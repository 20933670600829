/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.scss';
import {
  Container,
  Sticky,
  Menu,
  Icon,
  Grid,
  Divider,
} from 'semantic-ui-react';
import GoogleCse from './GoogleCse';

const Layout = ({ children, noH1 }) => {
  const refP = useRef();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div ref={refP}>
      <Sticky context={refP}>
        <Header siteTitle={data.site.siteMetadata.title} noH1={noH1} />
      </Sticky>
      <Container>
        <div className="google-cse-wrap">
          <GoogleCse></GoogleCse>
        </div>
        <main>{children}</main>
      </Container>

      <footer>
        <Container>
          <Grid stackable divided>
            <Grid.Column width={12}>
              <p className="app-information">
                Cocktail Meister is the best app to find all the best recipes of
                every drink in the planet. Search for your best drink, or browse
                by category. New features are on their way, stay tuned. Your
                feedbacks are very important for us to improve. Please feel free
                to contact us via contact form.
              </p>
            </Grid.Column>
            <Grid.Column width={4}>
              <Menu compact icon="labeled">
                <Menu.Item
                  name="facebook"
                  as="a"
                  href="https://www.facebook.com/CocktailMeisters/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="facebook" />
                  Facebook
                </Menu.Item>
                <Menu.Item
                  name="alexa"
                  as="a"
                  href="https://www.amazon.com/dp/B074Y8MFNL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="amazon" />
                  Alexa Skill
                </Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid>
          <Divider></Divider>
          <p>© {new Date().getFullYear()} Cocktail Meister</p>
        </Container>
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noH1: PropTypes.bool,
};

export default Layout;
