import React, { useEffect } from 'react';
const GoogleCse = () => {
  useEffect(() => {
    const cx = 'partner-pub-5019475886479300:zyp99h5v5gv';
    const gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);
  }, []);
  return <div className="gcse-search"></div>;
};

export default GoogleCse;
