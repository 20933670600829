import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Menu, Grid, Button, Dropdown } from 'semantic-ui-react';
import logo from '../images/cocktail.svg';

const Header = ({ siteTitle, noH1 }) => {
  const [collapsed, setCollapsed] = useState(
    JSON.parse(
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('headerMenuCollapsed')
        : 'true'
    )
  );

  const handleHamburgerClick = () => {
    localStorage && localStorage.setItem('headerMenuCollapsed', !collapsed);
    setCollapsed(!collapsed);
  };

  const menuItemClickHandler = () => {
    !collapsed && handleHamburgerClick();
  };

  const DivOrH1 = noH1 ? `div` : `h1`;
  return (
    <header>
      <Button
        icon={collapsed ? 'bars' : 'chevron up'}
        className="hamburger mobile only"
        onClick={handleHamburgerClick}
      ></Button>
      <Grid stackable padded divided>
        <Grid.Column tablet={5} computer={5} className="branding-wrap">
          <DivOrH1 className="branding">
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <img src={logo} alt="Cocktail Meister" />
              {siteTitle}
            </Link>
          </DivOrH1>
        </Grid.Column>
        <Grid.Column
          tablet={11}
          computer={11}
          floated="right"
          className={
            'menu-wrap ' + (collapsed ? 'menu-collapsed' : 'menu-expanded')
          }
        >
          <Menu stackable fluid>
            <Menu.Item
              name="classic"
              onClick={menuItemClickHandler}
              as={Link}
              to="/all-classic-cocktails"
            >
              Classic Cocktails
            </Menu.Item>
            <Menu.Item
              name="shots"
              onClick={menuItemClickHandler}
              as={Link}
              to="/all-shots-drinks"
            >
              Shots
            </Menu.Item>
            <Menu.Item
              name="punch"
              onClick={menuItemClickHandler}
              as={Link}
              to="/all-punch-party-cocktails"
            >
              Punch / Party
            </Menu.Item>
            <Menu.Item
              name="vodka"
              onClick={menuItemClickHandler}
              as={Link}
              to="/vodka"
            >
              Vodka
            </Menu.Item>
            <Menu.Item
              name="gin"
              onClick={menuItemClickHandler}
              as={Link}
              to="/gin"
            >
              Gin
            </Menu.Item>
            <Dropdown item text="More">
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  onClick={menuItemClickHandler}
                  to="/whiskey"
                  text="Whiskey"
                />
                <Dropdown.Item
                  as={Link}
                  onClick={menuItemClickHandler}
                  to="/brandy"
                  text="Brandy"
                />
                <Dropdown.Item
                  as={Link}
                  onClick={menuItemClickHandler}
                  to="/rum"
                  text="Rum"
                />
                <Dropdown.Item
                  as={Link}
                  onClick={menuItemClickHandler}
                  to="/tequila"
                  text="Tequila"
                />
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item
              name="contact"
              onClick={menuItemClickHandler}
              as={Link}
              to="/contact"
            >
              Contact
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  noH1: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
  noH1: false,
};

export default Header;
